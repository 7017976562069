import React from 'react';


const lanzamientos = () => {

  return (
    <div className='lanzamientos'>
 
    </div>
  );
};

export default lanzamientos;
